import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Toast } from 'primereact/toast';

const SEVERITY = {
  INFO: 'info',
  ERROR: 'error',
  WARN: 'warn',
  SUCCESS: 'success',
};

export const DEFAULT_TOAST_LIFE = 3000;

const EzToast = (props) => {
  const {
    life,
    toastTitle,
    toastBody,
    shouldShowToast,
    severity,
    position,
  } = props;
  console.log("EZToast", 'XXX');
  const toastRef = useRef(null);
  const showToast = () => {
    toastRef.current.show({
      severity,
      life,
      summary: toastTitle,
      detail: toastBody,
      position,
    });
  };

  useEffect(() => {
    shouldShowToast && showToast();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldShowToast]);

  return <Toast ref={toastRef} />;
};

EzToast.defaultProps = {
  life: DEFAULT_TOAST_LIFE,
  toastTitle: 'Operation completed Successfully!',
  toastBody: '',
  shouldShowToast: false,
  severity: 'success',
  position: 'top-right',
};

EzToast.propTypes = {
  life: PropTypes.number,
  toastTitle: PropTypes.string.isRequired,
  toastBody: PropTypes.string,
  shouldShowToast: PropTypes.bool,
  position: PropTypes.string,
  severity: PropTypes.oneOfType([
    SEVERITY.INFO,
    SEVERITY.ERROR,
    SEVERITY.WARN,
    SEVERITY.SUCCESS,
  ]),
};

export { EzToast, SEVERITY as TOAST_SEVERITY };
