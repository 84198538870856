import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Table, Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { fetchReimbursement } from '../Redux/Actions/Reimbursement/Reimbursement';
import SnackbarError from '../common/SnackbarError';
import SnackbarSuccess from '../common/SnackbarSuccess';
import ReactPaginate from 'react-paginate';
import edit from '../images/pencil.svg';
import deletebtn from '../images/close-button.svg';
import { Popup } from '../common/Popup';
import Cookies from 'universal-cookie';
import { deleteApi, loadierShow, loadingsShow } from '../Services/PostAPI';

const cookies = new Cookies();
let istSearchList = '';
export class Reimbursement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      PageNumber: 1,
      PageSize: 10,
      pageCount: 0,
      sort: {},
      Data: [],
      formOption: {},

      deleteModal: false,
      itemtodelete: '',
      textMessage: 'This will delete trip. Do you want to continue ?',
    };
  }
  handleDeleteModal = (id) => {
    this.setState((prevState) => {
      let { deleteModal, itemtodelete } = prevState;
      deleteModal = prevState.deleteModal === false ? true : false;
      itemtodelete = id;

      return { deleteModal, itemtodelete };
    });
  };
  handleRecordDelete = () => {
    let { itemtodelete } = this.state;
    deleteApi(
      `reimbursements/${itemtodelete}`,
      cookies.get('authorization')
    ).then((res) => {
      if (res.status === 200) {
        this.setState(
          {
            legalBoll: true,
            successMessage: 'Record deleted successfully',
          },
          function () {
            this.handleDeleteModal();
            setTimeout(
              function () {
                this.setState({
                  legalBoll: false,
                });
              }.bind(this),
              3000
            );
          }
        );
        this.getReimbursementStore();
      }
    });
  };
  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };
  getReimbursementStore = async () => {
    loadierShow('block');
    const { PageNumber, PageSize } = this.state;
    let reqData = {
      PageNumber: PageNumber,
      PageSize: PageSize,
    };
    await this.props.fetchReimbursement(reqData);

    let res = this.props.reimbursement;
    if (
      res &&
      res.reimbursementData &&
      res.reimbursementData.page &&
      res.reimbursementData.page.totalElements > 0
    ) {
      loadierShow('none');
      istSearchList = res.reimbursementData.page.totalElements;
      this.setState({
        Data: res.reimbursementData._embedded.content,
        pageCount: istSearchList / PageSize,
      });
    } else {
      loadierShow('none');
      // this.handelMessage(res.error.error, 'errorBoll');
    }
  };

  handleResultperpage = (event) => {
    let fieldName = event.target.name;
    let fleldVal = event.target.value;
    this.setState(
      {
        [fieldName]: fleldVal,
      },
      () => {
        this.getReimbursementStore();
      }
    );
  };

  handlePageClick = (data) => {
    let selected = data.selected + 1;
    let PageNumber = selected;

    this.setState({ PageNumber: PageNumber }, () => {
      this.getReimbursementStore();
    });
  };
  async componentDidMount() {
    await loadingsShow('block');
    await this.getReimbursementStore();
    await loadingsShow('none');
  }
  render() {
    const {
      formOption,
      Data,
      PageSize,
      PageNumber,
      pageCount,
      deleteModal,
      textMessage,
      access,
    } = this.state;

    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />

        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />
        <div className="paddingbox">
          <div className="containerbox">
            <div className="title orderscreen">
              <h3>Reimbursement</h3>
              <div className="rightbtn">
                <Link to="/Reimbursement/add-new">
                  {' '}
                  <Button className="primary-button">+ NEW</Button>
                </Link>
              </div>
            </div>

            {Data.length > 0 ? (
              <Col md={12} className="odrlist">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Trip No</th>
                      <th>Paid To</th>
                      <th>Total Amount</th>
                      <th colSpan="2">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Data.map((item) => (
                      <tr key={item.id}>
                        <td>{item.reimb_date}</td>
                        <td>Reimbursement</td>
                        <td>{item.reimb_status && item.reimb_status.code}</td>
                        <td>{item.trip && item.trip.trip_no}</td>
                        <td>{item.paid_to}</td>
                        <td>${item.hst_amt}</td>
                        <td>
                          {' '}
                          <Link
                            to={{ pathname: `/Reimbursement/${item.id}/edit` }}
                          >
                            {' '}
                            <img src={edit} alt="Edit" />
                          </Link>
                        </td>
                        <td>
                          {access &&
                          access.reimbursementsMenu &&
                          access.reimbursementsMenu.delete === true ? (
                            <img
                              className="delete"
                              onClick={() => this.handleDeleteModal(item.id)}
                              src={deletebtn}
                              alt="Delete"
                            />
                          ) : (
                            <img
                              className="delete drop"
                              src={deletebtn}
                              alt="Delete"
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            ) : (
              <div className="norecord">No record found</div>
            )}
          </div>
          <div className="paginationData">
            <div className="leftpage">
              <div className="showing">
                <span>Showing: </span>
                {PageSize * PageNumber + 1 - PageSize} -
                {PageSize > istSearchList
                  ? istSearchList
                  : PageSize * PageNumber > istSearchList
                  ? istSearchList
                  : PageSize * PageNumber}{' '}
                <span> of</span> {istSearchList} <span>entries. </span>{' '}
              </div>

              <div className="rowperpage">
                <Form.Group>
                  <Form.Label>Showing rows:</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={this.handleResultperpage}
                    name="PageSize"
                    value={PageSize}
                  >
                    <option>5</option>
                    <option>10</option>
                    <option>15</option>
                    <option>20</option>
                    <option>50</option>
                  </Form.Control>
                </Form.Group>
              </div>
            </div>

            <ReactPaginate
              previousLabel={'previous'}
              nextLabel={'next'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />

            <div className="clr"></div>
          </div>
        </div>
        <Popup
          isOpen={deleteModal}
          onConfirm={this.handleRecordDelete}
          textMessage={textMessage}
          toggleShow={this.handleDeleteModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    reimbursement: state.reimbursementReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetchReimbursement: (reqData) => dispatch(fetchReimbursement(reqData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Reimbursement);
