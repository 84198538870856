import { TOAST_SEVERITY } from "../../../common/EzToast";
import { EDIT_ASSET_LOCATION_RECORD } from "../../../constants/action-types/assetLocation";
import { 
  CREATE_ASSET_MAKE,
  CREATE_ASSET_RECORD,
  EDIT_ASSET_RECORD,
  FETCH_ASSETS_LIST_ERROR,
  FETCH_ASSET_DETAIL
} from "../../../constants/action-types/assets";
import { 
  CREATE_DOCUMENT_TYPE
} from "../../../constants/action-types/documents";

import { EDIT_ASSET_TRACK_RECORD } from "../../../constants/action-types/assetTracker";
import { RESET_TOAST_NOTIFICATION } from "../../../constants/action-types/common/notification";

const INITIAL_STATE = {
  shouldShowToast : false,
}

const successToast = ({ toastTitle = 'Operation completed Successfully!' }) => ({
  shouldShowToast: true,
  toastTitle,
  severity: TOAST_SEVERITY.SUCCESS
});

const failureToast = ({ toastTitle = 'Operation Failed!' }) => ({
  shouldShowToast: true,
  toastTitle,
  severity: TOAST_SEVERITY.ERROR
});

const toastReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){
    case EDIT_ASSET_RECORD.SUCCESS:
    case CREATE_ASSET_MAKE.SUCCESS:
    case CREATE_DOCUMENT_TYPE.SUCCESS:
    case EDIT_ASSET_TRACK_RECORD.SUCCESS:
    case EDIT_ASSET_LOCATION_RECORD.SUCCESS:  
    case CREATE_ASSET_RECORD.SUCCESS: {
      return { ...successToast({})  }
    }
    case EDIT_ASSET_TRACK_RECORD.ERROR:
    case EDIT_ASSET_LOCATION_RECORD.ERROR:
    case FETCH_ASSETS_LIST_ERROR:
    case CREATE_ASSET_MAKE.ERROR:
    case CREATE_DOCUMENT_TYPE.ERROR:
    case FETCH_ASSET_DETAIL.ERROR:
    case CREATE_ASSET_RECORD.ERROR:
    case EDIT_ASSET_RECORD.ERROR: {
      return { ...failureToast({}) }
    }
    case RESET_TOAST_NOTIFICATION: {
      return INITIAL_STATE
    }
    default :
     return state
  }
}

export default toastReducer