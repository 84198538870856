import React, { Component } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Prompt } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import DatePicker from 'react-datepicker';
import { Link } from 'react-router-dom';
import SnackbarError from '../common/SnackbarError';
import SnackbarSuccess from '../common/SnackbarSuccess';
import Cookies from 'universal-cookie';
import PDF_ICON from '../images/pdf.png';
import IMG_ICON from '../images/image.png';
import {
  getApiAsyn,
  formatDate,
  loadierShow,
  baseUrls,
  getFormattedDate,
  loadingsShow,
} from '../Services/PostAPI';
import { fetchTax } from '../Redux/Actions/Dropdown/TaxTypeDropdonw';
import { fetchReimbStatus } from '../Redux/Actions/Dropdown/ReimbStatusDropdonw';

import { post, put } from 'axios';
const cookies = new Cookies();
let FileData = [];
class CreateReimbursement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      access: JSON.parse(localStorage.getItem('menuAccess')),
      UIValue: {},
      PromptPrevent: false,
      TripDropdonw: [],
      taxList: [],
      ReimbStatusDropdonw: [],
      DriverDropdowns: [],
      errors: {},
      formOption: {},
      ImageDisplay: [],
    };
  }
  handleReset = () => {
    window.location.reload();
  };
  getTripList = async () => {
    let dddata = [],
      driverdd = [];
    let istSearchList = '';
    let res = await getApiAsyn(
      `trips?intent=load&paged=true&page=1&size=10000`,
      cookies.get('authorization')
    );
    if (res && res.status !== false) {
      console.log('DRV', res);
      istSearchList = res.page.totalElements;
      if (istSearchList > 0) {
        dddata = res._embedded.content;
        driverdd = res._embedded.content;
        dddata = dddata.map((item) => ({
          name: item.trip_no,
          value: item.id,
          driver1: item.driver1,
          driver2: item.driver2,
        }));
        if (driverdd.driver1) {
        }
        this.setState({
          TripDropdonw: dddata,
        });
      }
    }
  };

  getTax = async () => {
    let ddtax = [];

    await this.props.fetechTaxDropdown();
    let res = this.props.taxList.taxList;
    if (res && res.status !== false) {
      ddtax = res;
      ddtax = ddtax.map((item) => ({
        name: item.value,
        value: item.key,
      }));
      this.setState({
        taxList: ddtax,
      });
    }
  };
  handleSeachableDropdonw = (val, name) => {
    const { UIValue, taxList } = this.state;
    UIValue[name] = val;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });

    if (name === 'tax') {
      for (let i = 0; i < taxList.length; i++) {
        if (taxList[i].value === val) {
          this.setState({
            tax_label: taxList[i].name,
          });
        }
      }
      this.getCalculateTotal();
    }

    if (name === 'trip') {
      this.getDriverDropdonw(val);
    }
  };

  getDriverDropdonw = (val) => {
    const { TripDropdonw } = this.state;
    let Driver = TripDropdonw.filter((item) => item.value === val);
    let DriverDropdown = [];
    if (Driver && Driver.length>0 && Driver[0].driver1 != null && Driver[0].driver1 !== undefined) {
    DriverDropdown.push({
      value: Driver[0].driver1.id,
      name: Driver[0].driver1.first + ' ' + Driver[0].driver1.first,
    });
  }
    if (Driver && Driver.length>0 &&  Driver[0].driver2 != null && Driver[0].driver2 !== undefined) {
      DriverDropdown.push({
        value: Driver[0].driver2.id,
        name: Driver[0].driver2.first + ' ' + Driver[0].driver2.first,
      });
    }
    this.setState((prevState) => {
      let { DriverDropdowns } = prevState;
      DriverDropdowns = DriverDropdown;
      return { DriverDropdowns };
    });
  };

  getCalculateTotal = () => {
    const { taxList, UIValue } = this.state;
    for (let i = 0; i < taxList.length; i++) {
      if (taxList[i].value === UIValue.tax_type) {
        this.setState({
          tax_label: taxList[i].name,
        });
      }
    }
    this.setState((prevState) => {
      const { tax_label, UIValue } = prevState;

      if (
        tax_label &&
        !tax_label.includes('CustomTax') &&
        (tax_label !== undefined || tax_label !== '')
      ) {
        let taxPercent = tax_label.substring(tax_label.lastIndexOf('-') + 1);

        taxPercent = taxPercent.slice(0, -1);
        let ttl_amt =
          isNaN(UIValue.ttl_amt) ||
          UIValue.ttl_amt === undefined ||
          UIValue.ttl_amt == null ||
          UIValue.ttl_amt === ''
            ? 0
            : UIValue.ttl_amt;

        let taxtotal = (parseInt(ttl_amt) * taxPercent) / 100;
        UIValue.hst_amt = (parseInt(ttl_amt) + taxtotal).toFixed(2);
      } else {
        let ttl_amt =
          isNaN(UIValue.ttl_amt) ||
          UIValue.ttl_amt === undefined ||
          UIValue.ttl_amt == null ||
          UIValue.ttl_amt === ''
            ? 0
            : UIValue.ttl_amt;

        let taxPercentC =
          isNaN(UIValue.taxPercentC) ||
          UIValue.taxPercentC === undefined ||
          UIValue.taxPercentC == null ||
          UIValue.taxPercentC === ''
            ? 0
            : UIValue.taxPercentC;

        let taxtotal = (parseInt(ttl_amt) * taxPercentC) / 100;
        UIValue.hst_amt = (parseInt(ttl_amt) + taxtotal).toFixed(2);
      }
      return { UIValue };
    });
  };
  handleChangeGen = (event) => {
    const { UIValue } = this.state;
    UIValue[event.target.name] = event.target.value;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
    if (
      event.target.name === 'ttl_amt' ||
      event.target.name === 'taxPercentC'
    ) {
      this.getCalculateTotal();
    }
  };

  handleChangeGenFile = (event) => {
    const { UIValue } = this.state;
    if (event.target.value !== '') {
      UIValue[event.target.name] = event.target.value;
      if (event.target.type === 'file')
        UIValue[event.target.name] = event.target.files[0];
      FileData.push(event.target.files[0]);

      UIValue['files'] = event.target.files;

      this.setState({
        [UIValue]: UIValue,
        ImageDisplay: FileData,
        PromptPrevent: true,
      });
    }
  };

  handleRemoveFile = (item, i) => {
    let { UIValue, ImageDisplay } = this.state;

    let data = ImageDisplay;

    let newData = Object.values(data).filter((user, idx) => idx !== i);

    FileData.splice(i, 1);
    this.setState({
      ImageDisplay: newData,
      PromptPrevent: true,
      UIValue: {
        ...UIValue,
        files: newData,
      },
    });
  };

  handleChangeGenChecked = (event) => {
    const { UIValue } = this.state;
    UIValue[event.target.name] = event.currentTarget.value;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
  };

  handleChangeGenCheckedCB = (event) => {
    const { UIValue } = this.state;
    UIValue[event.target.name] = event.target.checked;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
  };
  handleDate = (date, name) => {
    const { UIValue } = this.state;
    UIValue[name] = date;
    this.setState({
      [UIValue]: UIValue,
      PromptPrevent: true,
    });
  };

  handleValidation = () => {
    const { UIValue } = this.state;

    let errors = {};
    let formIsValid = true;
    //Name

    if (!UIValue['trip']) {
      formIsValid = false;

      errors['trip'] = 'This is a mandatory field.';
    }

    if (!UIValue['trip_id']) {
      formIsValid = false;

      errors['trip_id'] = 'This is a mandatory field.';
    }

    if (!UIValue['paid_to']) {
      formIsValid = false;

      errors['paid_to'] = 'This is a mandatory field.';
    }

    this.setState({ errors: errors });
    return formIsValid;
  };
  getTripandDriverByid = () => {
    if (this.props.match.params.id) {
      this.setState({
        UIValue: {
          trip: parseInt(this.props.match.params.id),
        },
      });
    }
  };
  getReiumbById = async () => {
    if (this.props.match.params.id) {
      let res = await getApiAsyn(
        `reimbursements/${this.props.match.params.id}`,
        cookies.get('authorization')
      );
      if (res) {
        this.getDriverDropdonw(res.trip.id);
        res.reimb_status = res.reimb_status && res.reimb_status.id;
        res.trip = res.trip && res.trip.id;
        res.tax = res.tax && res.tax.id;
        res.reimb_date = new Date(
          getFormattedDate(res.reimb_date, 'YYYY-MM-DD hh:mm:ss', true)
        );
        res.trip_id = res.driver_id && res.driver_id;
        this.setState({
          UIValue: res,
        });
      }
    }
  };

  handleSave = async () => {
    if (this.handleValidation()) {
      const { UIValue } = this.state;

      let reqData = {
        id: 0,
        trip: UIValue.trip,
        trip_id: UIValue.trip_id,
        driver_id: UIValue.trip_id,
        reimb_date: formatDate(UIValue.reimb_date),
        paid_to: UIValue.paid_to,
        ttl_amt: parseFloat(UIValue.ttl_amt),
        tax: UIValue.tax,
        txn_amt: 2,
        hst_amt: UIValue.hst_amt,
        reimb_status: UIValue.reimb_status,
      };

      const formPayload = new FormData();
      let files = UIValue.files;
      if (files !== undefined && files.length > 5) {
        this.handelMessage('You can attach max 5 file in a order', 'errorBoll');
        return false;
      }
      files = FileData;
      if (files !== undefined && files.length > 0 && files.length < 5) {
        for (let i = 0; i < files.length; i++) {
          formPayload.append(`file`, files[i]);
        }
      }

      const json = JSON.stringify(reqData);
      const data = new Blob([json], {
        type: 'application/json',
      });
      formPayload.append('data', data);
      loadierShow('block');
      this.fileUpload(formPayload).then((response) => {
        console.log('BIAI', response);
        loadierShow('none');
        this.setState(
          {
            legalBoll: true,
            successMessage: 'Reimbursement created successfully!',
          },
          function () {
            setTimeout(
              function () {
                this.setState({
                  legalBoll: false,
                  PromptPrevent: false,
                });
                window.location.replace('#/Reimbursements');
              }.bind(this),
              3000
            );
          }
        );
      });
    }
  };

  fileUpload = (reqData) => {
    if (this.props.match.params.id) {
      const url = baseUrls + `reimbursements/${this.props.match.params.id}`;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookies.get('authorization'),
        },
      };
      return put(url, reqData, config);
    } else {
      const url = baseUrls + 'reimbursements';
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          authorization: cookies.get('authorization'),
        },
      };
      return post(url, reqData, config);
    }
  };

  handelMessage = (Message, Boll) => {
    const { formOption } = this.state;
    formOption[Boll] = true;
    formOption['Message'] = Message;
    this.setState(
      {
        formOption: formOption,
      },
      function () {
        setTimeout(
          function () {
            formOption[Boll] = false;
            this.setState({
              formOption: formOption,
            });
          }.bind(this),
          3000
        );
      }
    );
  };

  async componentDidMount() {
    await loadingsShow('block');
    await this.getTripList();
    this.getTax();
    this.props.fetchReimbStatus();
    this.getTripandDriverByid();
    if (this.props.match.path !== '/Reimbursement/add/:id') {
      await this.getReiumbById();
    }
    await loadingsShow('none');
  }
  componentDidUpdate(nextProps) {
    let dddata = [];
    if (this.props !== nextProps) {
      console.log('RES-BAID', this.props);
      let res = this.props.reimb_status.reimbStatusList;
      this.setState((prevState) => {
        let { ReimbStatusDropdonw } = prevState;
        if (res && res.status !== false) {
          dddata = res;
          dddata = dddata.map((item) => ({
            name: item.value,
            value: item.key,
          }));
          ReimbStatusDropdonw = dddata;
        }

        return { ReimbStatusDropdonw };
      });
    }
  }
  render() {
    const {
      errors,
      UIValue,
      taxList,
      TripDropdonw,
      formOption,
      DriverDropdowns,
      ReimbStatusDropdonw,
      PromptPrevent,
      tax_label,
      ImageDisplay,
      access,
    } = this.state;
    return (
      <div>
        <SnackbarError
          errorBoll={formOption.errorBoll}
          errorMessage={formOption.Message}
        />

        <SnackbarSuccess
          legalBoll={formOption.legalBoll}
          successMessage={formOption.Message}
        />
        <div className="paddingbox">
          <div className="containerboxform">
            <div className="title bdrb">
              <h3>
                {this.props.match !== undefined &&
                this.props.match !== '' &&
                this.props.match.params.id !== '' &&
                this.props.match.params.id !== undefined
                  ? 'Update Reimbursement'
                  : 'New Reimbursement'}
              </h3>
            </div>

            <div className="form">
              <Col md="12">
                <Row className="bdrb">
                  <Col md={6}>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Trip
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={TripDropdonw}
                              select="test"
                              name="trip"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'trip')
                              }
                              value={UIValue.trip}
                              placeholder="Select trip"
                            />
                            <span
                              className={
                                errors['trip'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['trip']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Driver
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={DriverDropdowns}
                              select="test"
                              name="trip_id"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'trip_id')
                              }
                              value={UIValue.trip_id}
                              placeholder="Select driver"
                            />
                            <span
                              className={
                                errors['trip_id'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['trip_id']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <Form.Group
                          className={
                            errors['reimb_date'] ? 'errorshows' : 'errorshides'
                          }
                          as={Row}
                        >
                          <Form.Label column sm="3" className="text-right">
                            Date
                          </Form.Label>
                          <Col sm="8">
                            <DatePicker
                              selected={UIValue.reimb_date}
                              dateFormat="dd-MM-yyyy"
                              className="form-control inpcalendar"
                              popperPlacement="start"
                              name="reimb_date"
                              autoComplete="off"
                              value={UIValue.reimb_date}
                              onChange={(date) =>
                                this.handleDate(date, 'reimb_date')
                              }
                            />
                            <span
                              className={
                                errors['reimb_date']
                                  ? 'errorshow'
                                  : 'errorshide'
                              }
                            >
                              {errors['reimb_date']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Paid To
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="paid_to"
                              value={UIValue.paid_to}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['paid_to'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['paid_to']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Total Amount
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="ttl_amt"
                              value={UIValue.ttl_amt}
                              onChange={this.handleChangeGen}
                            />
                            <span
                              className={
                                errors['ttl_amt'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['ttl_amt']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Tax
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={taxList}
                              select="test"
                              name="tax"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(val, 'tax')
                              }
                              value={UIValue.tax}
                              placeholder="Select tax"
                            />
                            <span
                              className={
                                errors['tax'] ? 'errorshow' : 'errorshide'
                              }
                            >
                              {errors['tax']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    {tax_label === 'CustomTax-1%' ? (
                      <Row>
                        <Col sm="12">
                          <Form.Group as={Row}>
                            <Form.Label column sm="3" className="text-right">
                              Custom Tax
                            </Form.Label>
                            <Col sm="8">
                              <Form.Control
                                name="taxPercentC"
                                value={UIValue.taxPercentC}
                                onChange={this.handleChangeGen}
                              />
                            </Col>
                          </Form.Group>
                        </Col>
                      </Row>
                    ) : null}

                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Amount w/o Tax
                          </Form.Label>
                          <Col sm="8">
                            <Form.Control
                              name="hst_amt"
                              value={UIValue.hst_amt}
                              disabled
                              onChange={this.handleChangeGen}
                            />
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Status
                          </Form.Label>
                          <Col sm="8">
                            <SelectSearch
                              container="rowselect"
                              search
                              options={ReimbStatusDropdonw}
                              select="test"
                              name="reimb_status"
                              autoComplete="sdf"
                              onChange={(val) =>
                                this.handleSeachableDropdonw(
                                  val,
                                  'reimb_status'
                                )
                              }
                              value={UIValue.reimb_status}
                              placeholder="Select status"
                            />
                            <span
                              className={
                                errors['reimb_status']
                                  ? 'errorshow'
                                  : 'errorshide'
                              }
                            >
                              {errors['reimb_status']}
                            </span>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12">
                        <Form.Group as={Row}>
                          <Form.Label column sm="3" className="text-right">
                            Attachment
                          </Form.Label>
                          <Col sm="8">
                            <Form.File
                              custom
                              type="file"
                              name="files"
                              label="Upload files"
                              onChange={this.handleChangeGenFile}
                            />
                            <div className="filetoupload">
                              <ul>
                                {ImageDisplay &&
                                  ImageDisplay.length > 0 &&
                                  ImageDisplay.map((item, i) => (
                                    <li>
                                      <span className="imgName">
                                        {' '}
                                        {item.name}
                                      </span>
                                      <span className="imgHolder">
                                        {item.type === 'application/pdf' ? (
                                          <img src={PDF_ICON} alt="img" />
                                        ) : (
                                          <img src={IMG_ICON} alt="img" />
                                        )}
                                      </span>
                                      <span
                                        className="remove"
                                        onClick={() =>
                                          this.handleRemoveFile(item, i)
                                        }
                                      >
                                        x
                                      </span>
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="text-right">
                    <Link to="/Reimbursements">
                      <Button className="secondary-button">Cancel</Button>
                    </Link>
                    <Button
                      onClick={this.handleReset}
                      className="secondary-button"
                    >
                      Reset
                    </Button>
                    {(this.props.match.params.id &&
                      access &&
                      access.reimbursementsMenu &&
                      access.reimbursementsMenu.update === true) ||
                    (!this.props.match.params.id &&
                      access &&
                      access.reimbursementsMenu &&
                      access.reimbursementsMenu.create === true) ? (
                      <Button
                        onClick={this.handleSave}
                        className="primary-button"
                      >
                        Save
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </Col>
            </div>
          </div>
        </div>
        {PromptPrevent ? (
          <Prompt
            message={() =>
              'Moving away from this page will remove any unsaved data. Are you sure?'
            }
          />
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    taxList: state.taxReducer,
    reimb_status: state.reimbReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    fetechTaxDropdown: (reqData) => dispatch(fetchTax(reqData)),
    fetchReimbStatus: (data) => dispatch(fetchReimbStatus(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateReimbursement);
