import { getApiAsyn } from '../../../Services/PostAPI';
import Cookies from 'universal-cookie';
import { GET_TAX_TYPE } from '../../Type/ReimbursementType';
const cookies = new Cookies();

export const GetTaxType = (data) => {
  return {
    type: GET_TAX_TYPE,
    payload: data,
  };
};

export const fetchTax = (reqData) => {
  return async (dispatch) => {
    let res = await getApiAsyn(
      `types?type=TAX_TYPE`,
      cookies.get('authorization')
    );
    if (res.status !== false) {
      dispatch(GetTaxType(res));
    } else {
      dispatch(GetTaxType(res));
    }
  };
};


