import { getApiAsyn } from '../../../Services/PostAPI';
import Cookies from 'universal-cookie';
import { GET_REIMB_STATUS } from '../../Type/ReimbursementType';
const cookies = new Cookies();

export const GetReimbType = (data) => {
  return {
    type: GET_REIMB_STATUS,
    payload: data,
  };
};

export const fetchReimbStatus = (reqData) => {
  return async (dispatch) => {
    let res = await getApiAsyn(
      `types?type=REIMB_STATUS`,
      cookies.get('authorization')
    );
    if (res.status !== false) {
      dispatch(GetReimbType(res));
    } else {
      dispatch(GetReimbType(res));
    }
  };
};
