import { combineReducers } from 'redux';
import { reimbursementReducer } from './Reimbursement/ReimbursementReducer';
import { taxReducer } from './Dropdown/TaxTypeDropdown';
import { reimbReducer } from './Dropdown/ReimbStatusDropdown';
import { accidentsReducer } from './accidents';
import { inspectionsReducer } from './inspections';
import { trackingLogsReducer } from './trackingLogs';
import { employeeReducer } from './employee';
import { typesReducer } from './types';
import { provinceReducer } from './common/province';
import formStateReducer from './common/form';
import { documentsReducer } from '../Reducers/Documents_new';
import { dropDownReducer } from './Dropdown';
import { assetsReducer } from './assets_new';
import { vaultReducer } from '../Reducers/vault';
import { filesAttachmentsReducer } from './files';
import { compliancesReducer } from './compliance';
import { userProfileReducer } from '../Reducers/profile'
import { customerReducer } from '../Reducers/customer'
import {assetTrackReducer} from './assets_new/assetTracker';
import toastReducer from './Notification/Toast';
import assetLocationsReducer from './assets-location';
import { inventoryTrackersReducer } from './inventoryTracker'

const rootReducers = combineReducers({
  accidentsReducer,
  compliancesReducer,
  inspectionsReducer,
  assetsReducer,
  typesReducer,
  employeeReducer,
  provinceReducer,
  trackingLogsReducer,
  reimbReducer: reimbReducer,
  reimbursementReducer: reimbursementReducer,
  taxReducer: taxReducer,
  documentsReducer,
  dropDownReducer,
  formStateReducer,
  filesAttachmentsReducer,
  vaultReducer,
  userProfileReducer,
  customerReducer,
  assetTrackReducer,
  toastReducer,
  assetLocationsReducer,
  inventoryTrackersReducer
});

export default rootReducers;
