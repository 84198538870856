import { getApiAsyn } from '../../../Services/PostAPI';
import Cookies from 'universal-cookie';
import {
  GET_REQUEST_REIMBURSEMENT,
  GET_SUCCESS_REIMBURSEMENT,
  GET_FAILURE_REIMBURSEMENT,
} from '../../Type/ReimbursementType';
const cookies = new Cookies();
export const GetReimbursementRequest = () => {
  return {
    type: GET_REQUEST_REIMBURSEMENT,
  };
};
export const GetReimbursementSuccess = (data) => {
  return {
    type: GET_SUCCESS_REIMBURSEMENT,
    payload: data,
  };
};
export const GetReimbursementFailure = (error) => {
  return {
    type: GET_FAILURE_REIMBURSEMENT,
    payload: error,
  };
};

export const fetchReimbursement = (reqData) => {
  return async (dispatch, getState) => {
    console.log('BAID', getState());
    dispatch(GetReimbursementRequest());
    let res = await getApiAsyn(
      `reimbursements?paged=true&page=${reqData.PageNumber}&size=${reqData.PageSize}&sort=id,desc`,
      cookies.get('authorization')
    );
    if (res.status !== false) {
      dispatch(GetReimbursementSuccess(res));
    } else {
      dispatch(GetReimbursementFailure(res));
    }
  };
};
