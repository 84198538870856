import { transformEmployees } from "../../../utils/dropDown/transformtoDropdown";

const getDropdownreducer = state => state.dropDownReducer;
export const getDocumentsTypes = state => getDropdownreducer(state).documentTypes
export const getOwnerTypes = state => getDropdownreducer(state).ownerTypes;
export const getOwners = state => getDropdownreducer(state).owners;
export const getSuppliers = state => getDropdownreducer(state).suppliers;
export const getMake = state => getDropdownreducer(state).make;
export const getModel = state => getDropdownreducer(state).model;

const getEmployeeReducer = state => state?.employeeReducer;
export const getEmployeesdropDown = state => {
  const employees =  getEmployeeReducer(state)?.employees;
  return transformEmployees(employees);
}
