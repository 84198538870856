export const APP_URLS = {
  DASHBOARD: {
    HOME: "/Dashboard",
  },
  ASSETS_LOCATION: {
    HOME: "/asset-location",
    HISTORY: "/asset-location/:id/history"
  },
  TRIPS: {
    HOME: '/fleet/trips',
    ADD_TRIPS: '/fleet/trip/add-new',
    EDIT_TRIPS: '/fleet/trips/:id/edit',
  },
  ORDERS: {
    HOME: '/fleet/orders',
    ADD_TRIPS: '/fleet/orders/add-new',
    EDIT_TRIPS: '/fleet/orders/:id/edit',
  },
  CUSTOMERS: {
    HOME: '/customers/customer-and-brokers',
    ADD_CUSTOMERS: '/customers/customer/add-new',
    EDIT_CUSTOMERS: '/customers/customer/:id/edit',
  },
  ASSETS: {
    HOME: '/customers/assets',
    ADD_ASSETS: '/customers/assets/add-new',
    EDIT_ASSETS: '/customers/assets/:id/edit',
  },

  COMPLIANCE: {
    HOME: '/Compliance',
    ADD_COMPLIANCE: '/Compliance/add-new',
    EDIT_COMPLIANCE: '/Compliance/:complianceId/edit',
  },
  INVENTORY_TRACKERS: {
    HOME: '/Inventory-tracker',
    ADD_INVENTORY_TRACKER: '/Inventory-tracker/add-new',
    EDIT_INVENTORY_TRACKER: '/Inventory-tracker/:inventoryTrackerId/edit',
  },
  EMPLOYEES: {
    HOME: '/Employee',
    ADD_EMPLOYEES: '/Employee/add-new',
    EDIT_EMPLOYEES: '/Employee/:id/edit',
  },
  REIMBURSEMENTS: {
    HOME: '/Reimbursements',
    ADD_REIMBURSEMENTS: '/Reimbursements/add-new',
    EDIT_REIMBURSEMENTS: '/Reimbursements/:id/edit',
  },
  ACCIDENTS: {
    HOME: '/accidents',
    ADD_ACCIDENT: '/accidents/add-new',
    EDIT_ACCIDENT: '/accidents/:accidentId/edit'
  },
  INSPECTION: {
    HOME: '/inspections',
    ADD_INSPECTION: '/inspections/add-new',
    EDIT_INSPECTION: '/inspections/:inspectionId/edit',
  },
  DOCUMENT: {
    HOME: '/Documents',
    ADD_DOCUMENT:'/Documents/add-new',
    EDIT_DOCUMENT: '/Documents/:id/edit' //'/documents-new/:documentId/edit'
  },
  USER_PROFILE: { HOME: "/UserProfile" },
  TRACKING_LOG: { HOME: "/tracking-logs" },
  VAULT: {
    HOME: '/vault',
    ADD_VAULT: '/vault/add-new',
    EDIT_VAULT: '/vault/:vaultId/edit'
  },
};

export const API_URL = {
  TYPES: {
    FETCH_TYPE: 'types',
    VEHICLE_TYPE: 'VEHICLE_TYPE',
    EQUIP_TYPE: 'EQUIP_TYPE',
    ASSET_TRACKING_STATUS: 'ASSET_TRACKING_STATUS',
    TRACKING_DEVICE_PROVIDER: 'TRACKING_DEVICE_PROVIDER',
    RENTAL_STATUS: 'RENTAL_STATUS'
  },
  ACCIDENTS: {
    FETCH_ACCIDENT: 'accidents',
    FETCH_BY_ACCIDENT_ID: 'accidents/:id',
    DELETE_ACCIDENT: 'accidents',
    CREATE_ACCIDENT: 'accidents',
    EDIT_ACCIDENT: 'accidents/:id',
  },
  INVENTORY_TRACKERS: {
    FETCH_INVENTORY_TRACKERS: 'equipment-rentals',
    FETCH_BY_INVENTORY_TRACKERS_ID: 'equipment-rentals/:id',
    DELETE_INVENTORY_TRACKERS: 'equipment-rentals',
    CREATE_INVENTORY_TRACKERS: 'equipment-rentals',
    EDIT_INVENTORY_TRACKERS: 'equipment-rentals/:id',
  },
  ASSET_LOCATION: {
    FETCH_ASSET_LOCATION: 'locations',
    FETCH_BY_ASSET_LOCATION_ID: 'locations/:id',
    FETCH_ASSET_LOCATION_HISTORY: 'locations/:id/history',
    EDIT_ASSET_LOCATION: 'locations/:id',
  },
  COMPLIANCE: {
    FETCH_COMPLIANCE: 'compliances',
    FETCH_BY_COMPLIANCE_ID: 'compliances/:id',
    DELETE_COMPLIANCE: 'compliances',
    CREATE_COMPLIANCE: 'compliances',
    EDIT_COMPLIANCE: 'compliances/:id',
  },
  INSPECTIONS: {
    FETCH_INSPECTION: 'inspections',
    FETCH_BY_INSPECTION_ID: 'inspections/:id',
    DELETE_INSPECTION: 'inspections',
    CREATE_INSPECTION: 'inspections',
    EDIT_INSPECTION: 'inspections/:id',
    FETCH_INSPECTION_FILES:'inspections'
  },
  EMPLOYEES: {
    FETCH_EMPLOYEES: 'employees',
    FETCH_EMPLOYEES_BY_ID: 'employees/:id'
  },
  ASSETS: {
    FETCH_ASSETS: 'assets',
    DELETE_ASSETS: 'assets',
    CREATE_ASSETS: 'assets',
    FETCH_ASSETS_BY_ID: 'assets/:id',
    FETCH_BY_ASSET_ID: 'assets/:id',
    EDIT_ASSET: 'assets/:id',
    FETCH_ASSET_MAKE: 'asset/make',
    FETCH_ASSET_MODEL: 'asset/model',
    CREATE_ASSET_MAKE: 'asset/make',
    CREATE_ASSET_MODEL: 'asset/model',
  },
  PROVINCE: {
    FETCH_PROVINCES: 'states',
  },
  DOCUMENTS:{
    FETCH_DOCUMENT:'documents',
    CREATE_DOCUMENT:'documents',
    DELETE_DOCUMENT :'documents',
    EDIT_ACCIDENT: 'documents/:id',
    FETCH_BY_DOCUMENT_ID: 'documents/:id',
    FETCH_DOCUMENT_TYPES: 'types?type=BS_DOC_TYPE'
  },
  CUSTOMERS:{
    FETCH_CUSTOMERS:'customers',
    FETCH_CUSTOMER_BY_ID : 'customers/:id'
  },
  SUPPLIERS:{
    FETCH_SUPPLIERS:'suppliers',
    FETCH_SUPPLIERS_BY_ID : 'suppliers/:id'
  },
  ASSET_TRACKING: {
    CREATE_ASSET_TRACK: 'assettrack',
    FETCH_ASSET_TRACK_ID:'assettrack/assets/:id',
    EDIT_ASSET_TRACK_ID:'assettrack/:id'
  },
  FETCH_OWNER_TYPES : `types?type=OWNER_TYPE`,
  TRACKING_LOGS:{
    FETCH_TRACKING_LOGS:'tracking',
  },
  VAULT: {
    FETCH_VAULT: 'vault',
    FETCH_BY_VAULT_ID: 'vault/:id',
    DELETE_VAULT: 'vault',
    CREATE_VAULT: 'vault',
    EDIT_VAULT: 'vault/:id',
  },
  PROFILE: {
    FETCH_USER_COMPANY_INFO: 'profile/company',
    FETCH_USER_INFO: 'profile/user'
  },
  SWITCH_ACCOUNT: {
    IS_ELIGIBLE_FOR_SWITCH: 'switch/active',
    FETCH_ACCOUNTS: 'switch/manage',
    SWITCH_TO_ACCOUNT_ID: 'switch/:id',
    SWITCH_BACK_TO_SELF_ACCOUNT: 'switch/manager'
  },
  LOCATION: {
    FETCH_BY_CS_NAME: 'places?search=:id&screen=:type',
    PLACE_AUTOCOMPLETE :'places/autocomplete',
    PLACE_DETAIL:'places/detail'
  },
  TRIPS: {
    BORDER_CONNECT: 'border/connect'
  },
  DROPDOWN: 'dropdown/',
  DROPDOWN_TYPE: {
    BS_DOC_TYPE: 'BS_DOC_TYPE',
  }
};
