import { DEFAULT_TOAST_LIFE } from "../../../../common/EzToast";
import { API_URL } from "../../../../constants/urlConstants";
import { 
        createAssetTrack,
        editAssetTracker,
        fetchAssetTrackerByAssetId
       } from "../../../../Services/business/assetTracker"
import { 
        CREATE_ASSET_TRACK_RECORD,
        FETCH_ASSET_TRACK_DETAIL,
        EDIT_ASSET_TRACK_RECORD, 
        INIT_CREATE_NEW_ASSET_TRACKER_SCREEN,
        TOAST
      } from "../../../../constants/action-types/assetTracker";

import { fetchEmployees } from "../../employees";
import { fetchTypes } from "../../types";

export const initAssetTrackerScreen = () => ({ type: INIT_CREATE_NEW_ASSET_TRACKER_SCREEN })

export const initializeAssetsTrackerScreen = () => (dispatch) => {
  dispatch(initAssetTrackerScreen());
  dispatch(fetchTypes({ params : API_URL.TYPES.ASSET_TRACKING_STATUS }))
  dispatch(fetchTypes({ params : API_URL.TYPES.TRACKING_DEVICE_PROVIDER }))
}

export const assetTrackerDetailFetchStart = (payload) => { 
  return {
  type: FETCH_ASSET_TRACK_DETAIL.START,
  payload,
}}

export const assetTrackerDetailFetchSuccess = (payload) => ({
  type: FETCH_ASSET_TRACK_DETAIL.SUCCESS,
  payload,
});

export const assetTrackerDetailFetchError = (payload) => ({
  type: FETCH_ASSET_TRACK_DETAIL.ERROR,
  payload,
});

export const fetchAssetTrackerDetailsByassetId = (payload) => {
  return async (dispatch) => {
    dispatch(assetTrackerDetailFetchStart(payload));
    let assetTrackerData = await fetchAssetTrackerByAssetId(payload);
      if (!assetTrackerData.isError) {
        dispatch(assetTrackerDetailFetchSuccess(assetTrackerData));
      } else {
        dispatch(assetTrackerDetailFetchError(assetTrackerData));
      } 
  };
};

export const resetToast = (payload) => ({
  type: TOAST.RESET,
  payload,
});

export const createAssetTrackRecordStart = () => ({
  type: CREATE_ASSET_TRACK_RECORD.START,
});

export const createAssetTrackRecordSuccess = (payload) => ({
  type: CREATE_ASSET_TRACK_RECORD.SUCCESS,
  payload,
});

export const createAssetTrackRecordError = (payload) => ({
  type: CREATE_ASSET_TRACK_RECORD.ERROR,
  payload,
});

export const createAssetTracker = (payload) => {
  return (dispatch) => {
    dispatch(createAssetTrackRecordStart());
    createAssetTrack(payload).then((data) => {
      if (!data.isError) {
        dispatch(createAssetTrackRecordSuccess());
      } else {
        dispatch(createAssetTrackRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };
};  

export const editAssetTrackerRecordStart = () => ({
  type: EDIT_ASSET_TRACK_RECORD.START,
});

export const editAssetTrackerRecordSuccess = (payload) => ({
  type: EDIT_ASSET_TRACK_RECORD.SUCCESS,
  payload,
});

export const editAssetTrackerRecordError = (payload) => ({
  type: EDIT_ASSET_TRACK_RECORD.ERROR,
  payload,
});

export const editAssetTrackerRecord = (payload) => {
  return (dispatch) => {
    dispatch(editAssetTrackerRecordStart());
    editAssetTracker(payload).then((data) => {
      if (!data.isError) {
        dispatch(editAssetTrackerRecordSuccess());
      } else {
        dispatch(editAssetTrackerRecordError(data));
      }
      setTimeout(() => {
        dispatch(resetToast());
      }, DEFAULT_TOAST_LIFE + 1000);
    });
  };  
};

export const fetchDriver = (payload) => async (dispatch) => {
  dispatch(fetchEmployees(payload))
} 


