import {createActions } from 'redux-actions'

export const {fetchDocumentTypesStart,fetchDocumentTypesComplete} = createActions({
    'FETCH_DOCUMENT_TYPES_START':null,
    'FETCH_DOCUMENT_TYPES_COMPLETE':null
})

export const {fetchOwnerTypesStart,fetchOwnerTypesComplete} = createActions({
    'FETCH_OWNER_TYPES_START':null,
    'FETCH_OWNER_TYPES_COMPLETE':null
})

export const {fetchOwnerStart, fetchOwnerComplete} = createActions({
    'FETCH_OWNER_START':null,
    'FETCH_OWNER_COMPLETE':null
})

export const {fetchSuppliersStart, fetchSuppliersComplete} = createActions({
    'FETCH_SUPPLIERS_START':null,
    'FETCH_SUPPLIERS_COMPLETE':null
})
export const { fetchAssetMakeStart, fetchAssetMakeComplete } = createActions({
    'FETCH_ASSET_MAKE_START': null,
    'FETCH_ASSET_MAKE_COMPLETE': null
})

export const { fetchAssetModelStart, fetchAssetModelComplete } = createActions({
    'FETCH_ASSET_MODEL_START': null,
    'FETCH_ASSET_MODEL_COMPLETE': null
})
