import {
  GET_REQUEST_REIMBURSEMENT,
  GET_SUCCESS_REIMBURSEMENT,
  GET_FAILURE_REIMBURSEMENT,
} from '../../Type/ReimbursementType';

const initalState = {
  loading: false,
  reimbursementData: [],
  error: {},
  token: '',
};

export const reimbursementReducer = (state = initalState, action) => {
  switch (action.type) {
    case GET_REQUEST_REIMBURSEMENT:
      return {
        ...state,
        loading: true,
      };
    case GET_SUCCESS_REIMBURSEMENT:
      return {
        ...state,
        loading: false,
        reimbursementData: action.payload,
        error: {},
      };
    case GET_FAILURE_REIMBURSEMENT:
      return {
        ...state,
        loading: false,
        reimbursementData: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
