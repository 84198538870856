/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Row, Container } from 'react-bootstrap';
import { classNames } from 'primereact/utils';
import { Button } from 'primereact/button';
import EzControlledInputWithFloatingLabel from '../../../common/EzForm/EzControlledInput/WithFloatingLabel';
import './index.css';
import { EzToast, TOAST_SEVERITY } from '../../../common/EzToast';
import { getFormBaseValidationRule } from '../../../utils/form/form-utils';


const CreateAsset = (props) => {

  const {
    formFieldValueMap,
    isAssetLocationDetailSuccess,
    editAssetLocation,
    closeDialog
  } = props
  
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm({defaultValues: useMemo(() => formFieldValueMap, [formFieldValueMap])});

  const getFormErrorMessage = (name) => {
    return ( errors[name] && <small className="p-error">{errors[name].message}</small> );
  };

  const onSubmit = (data) => {
    const { reason, moved } = data;
    const reqBody = {
      id: formFieldValueMap?.id,
      asset: formFieldValueMap?.asset?.id,
      comment: reason,
      moved
    };
    closeDialog();
    editAssetLocation({ reqBody });
  };
  
  useEffect(() => {
    if (isAssetLocationDetailSuccess) {
      reset({
        ...formFieldValueMap,
        asset_code: formFieldValueMap?.asset?.asset_code,
        moved: formFieldValueMap?.moved,
        reason: formFieldValueMap?.comment,
      });
      }
  }, [isAssetLocationDetailSuccess]);
      
  const [showErrorToast, setErrorToast] = useState(false);
  const getToastProps = () => {
    return {
      severity: TOAST_SEVERITY.ERROR,
      toastTitle: 'Please fill all mandatory fields',
      shouldShowToast: true,
    };
  };

  useEffect(() => {
    if (Object.keys(errors).length) {
      setErrorToast(true);
    }
  }, [errors]);

  const renderAsset = () => {
    const name = 'asset_code';
    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        disabled = {true}
        control={control}
        label={'Asset Code'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderReason = () => {
    const name = 'reason';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'Reason'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };

  const renderMovedLocation = () => {
    const name = 'moved';

    return (
      <EzControlledInputWithFloatingLabel
        name={name}
        control={control}
        label={'moved Location'}
        rules={getFormBaseValidationRule().DEFAULT.VALIDATION_RULE}
        isError={errors[name]}
        errorMsg={getFormErrorMessage(name)}
      />
    );
  };
  
  const resetAll = () => {
    reset({
      asset_code: formFieldValueMap?.asset?.asset_code,
      reason: formFieldValueMap?.comment,
      moved: formFieldValueMap?.moved
    });
  } 
  return (
    <> 
    <div className="">
      <div>
        <div
          className="form-demo"
          style={{ paddingLeft: '10px', paddingRight: '10px' }}
        >
          <div className="card" style={{ background: '#f3faf4BF' }}>
            {showErrorToast && <EzToast { ...getToastProps() } />}
            <form onSubmit={handleSubmit(onSubmit)} className="p-fluid">
              <Container fluid>
                <Row style={{ minWidth: '20px' }}>
                  <Col md="3">{renderAsset()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderMovedLocation()}</Col>
                  <Col md="1" />
                  <Col md="3">{renderReason()}</Col>
                </Row>
              </Container>
              <div
                className={'make-flex mt-3 mr-2'}
                style={{ flexDirection: 'row-reverse', background: 'white' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      label="Cancel"
                      onClick={() => closeDialog()}
                      className="p-btn-danger p-button-outlined mt-2"
                    />
                  </div>
                  <div style={{ margin: '15px 10px', minWidth: '100px' }}>
                    <Button
                      loading={false}
                      icon={'pi pi-pencil'}
                      type="submit"
                      label={'Edit'}
                      className="mt-2 p-button-raised"
                    />    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default CreateAsset;
