import { APP_URLS } from '../../constants/urlConstants';
import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getItems = (access, history, loggedInUserInfo, goToDashboard, handleLogout, fetchSwitchableAccounts) => {
  const items = [
    {
      label: 'Dashboard',
      command: goToDashboard,
    },
    {
      label: 'Trips',
      visible: access?.tripMenu?.read,
      command: () => {  history.push(APP_URLS.TRIPS.HOME) },
    },
    {
      label: 'Orders',
      visible: access?.orderMenu?.read,
      command: () => {  history.push(APP_URLS.ORDERS.HOME) },
    },
    {
      label: 'Customers',
      visible: access?.customerMenu?.read,
      command: () => {  history.push(APP_URLS.CUSTOMERS.HOME) },
    },
    {
      label: 'My Documents',
      visible: access?.documentMenu?.read,
      command: () => {  history.push(APP_URLS.DOCUMENT.HOME) },
    },
    {
      label: 'My Assets',
      items: [{
        label: 'Assets',
        visible: access?.assetsMenu?.read,
        command: () => {  history.push(APP_URLS.ASSETS.HOME) },
      },
      {
        label: 'Tracking Logs',
        visible: access?.logTrackingMenu?.read,
        command: () => { history.push(APP_URLS.TRACKING_LOG.HOME) },
      },
      {
        label: 'Asset Location',
        visible: access?.assetLocationMenu?.read,
        command: () => { history.push(APP_URLS.ASSETS_LOCATION.HOME) },
      },
      {
        label: 'Inventory Tracker',
        visible: access?.assetLocationMenu?.read,
        command: () => { history.push(APP_URLS.INVENTORY_TRACKERS.HOME) },
      }
    ]
    },
    {
      label: 'Compliance Corner',
      items: [{
          label: 'Accidents',
          visible: access?.accidentMenu?.read,
          command: () => { history.push(APP_URLS.ACCIDENTS.HOME) }
        },
        {
          label: 'Compliance',
          visible: access?.complianceMenu?.read,
          command: () => { history.push(APP_URLS.COMPLIANCE.HOME) }
        },
        {
          label: 'Inspections',
          visible: access?.inspectionMenu?.read,
          command: () => { history.push(APP_URLS.INSPECTION.HOME) }
        },
        {
          label: 'Vault',
          visible: access?.vaultMenu?.read,
          command: () => { history.push(APP_URLS.VAULT.HOME) }
        },
      ]
    },
    {
      label: 'Payroll',
      items: [{
          label: 'Employee',
          visible: access?.employeeMenu?.read,
          command: () => { history.push(APP_URLS.EMPLOYEES.HOME) }
        },
        {
          label: 'Reimbursement',
          visible: access?.reimbursementsMenu?.read,
          command: () => { history.push(APP_URLS.REIMBURSEMENTS.HOME) }
        },
      ]
    },
    {
      label: 'Quick Links',
      items: [{
          label: 'ACI/ACE Portal',
          visible: true,
          command: () => { window.open("https://emanifest.easyonroad.com/", "_blank"); }
        }
      ]
    },
  ];
  
  const endItems = [{
    label: 'Profile',
    command: () => { history.push(APP_URLS.USER_PROFILE.HOME) }
  },
  {
    label: 'Switch Account',
    disabled: !loggedInUserInfo?.isUserEligibleForAccountSwitch ?? true,
    command: fetchSwitchableAccounts
  },
  {
    label: 'Logout',
    command: () => { handleLogout() }
  }
]

  return [items, endItems];
}

  // TODO (Subham): Implement recursion here for multi level items
  export const getFilteredItems = (items) => {
  const newItems = []
  if (items) {
    items.forEach(item => {
      if (item.visible !== false) {

        if (item?.items?.length) {
          const newL2ITems = [];
          item.items.forEach(l2ITem => {
            if (l2ITem.visible !== false) {
              newL2ITems.push(l2ITem);
            }
          })
          item.items = newL2ITems;
        }
        
        newItems.push(item)
      }
    })
  }
  return newItems;
}

export const handleLogout = () => {
  cookies.remove('authorization');
  cookies.remove('first');
  cookies.remove('companyname');
  cookies.remove('companyImage');
  cookies.remove('profile_img');
  localStorage.getItem('menuAccess');
  localStorage.removeItem('menuAccess');
  sessionStorage.removeItem("switchAccountBanner");
  window.location.replace('/');
};